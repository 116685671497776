import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";

let config ={
  headerHeight: 42,
  footerHeight: 25,
}
export function generatePDF1({ state, challanNo, combinedData, letterHead }) {
  const doc = new jsPDF({ orientation: "p", compress: true });

  const totalWidth = doc.internal.pageSize.getWidth();
  const totalHeight = doc.internal.pageSize.getHeight();
  const margin = 5;
  const borderMargin = 2;

  const drawMarginRect = (doc) => {
    doc
      .setDrawColor(0)
      .setLineWidth(0.5)
      .rect(
        borderMargin,
        borderMargin + 20 + 22,
        totalWidth - 2 * borderMargin,
        totalHeight - 2 * borderMargin - 60
      );
  };

  let currentY = addChallanDetails(doc);
  currentY = addChallanTable(doc, state, challanNo, currentY, margin);
  currentY = addDataTable(
    doc,
    combinedData,
    currentY,
    margin,
    drawMarginRect,
    letterHead
  );

  // Add Note at the end
  const remainingHeight =  doc.internal.pageSize.getHeight() - config.footerHeight + 10;
  const termsHeight = currentY + 5;
  doc.setFontSize(9);
  if (remainingHeight > termsHeight) {
    currentY += 1
  } else {
    doc.addPage();
    currentY = config.headerHeight + 15
      addHeaderFooter(doc,letterHead);
    drawMarginRect(doc);
  }
  doc.text(
    `Note- Goods not sold, Returned back to consignee after testing, labour & job only. \nNo.of Packs : Carried by Throgh ${state?.dispatchMedium} `,
    margin,
    currentY
  );

  doc.save("Challan_Details.pdf");
}

function addChallanDetails(doc) {
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.setTextColor("#474383");
  doc.text("DELIVERY CHALLAN", doc.internal.pageSize.width / 2, 30 + 22, {
    align: "center",
  });

  doc.setFontSize(9);
  doc.setTextColor(0, 0, 0);
  doc.text("Returnable / Non-Returnable", doc.internal.pageSize.width / 2, 34 + 22, {
    align: "center",
  });

  return 38 + 22; // Next Y position after title
}

function addChallanTable(doc, state, challanNo, startY, margin) {
  const tableRows = [];

  const data = [
    ["Customer's Name", state?.clientName || "", "Challan No", challanNo || ""],
    [
      "Address",
      state?.address || "",
      "Challan Date",
      moment(state?.dispatchDate).format("DD-MM-YYYY") || "",
    ],
    [
      "Contact Person Name",
      state?.contactPersonName || "",
      "Customer Challan No",
      state?.dcNumber || "",
    ],
    ["Email", state?.emailId || "", "Contact Number", state?.contact || ""],
    ["SRF", state?.serviceReqNumber || "", "Customer Challan Date", moment(state?.dcDate).format("DD-MM-YYYY") || ""],
  ];

  data.forEach((row) => tableRows.push(row));
    // Calculate the total available width of the table
    const totalPageWidth = doc.internal.pageSize.getWidth() -
    margin * 2;
    const columnPercentages = [20, 35, 20, 25];
    const columnWidths = columnPercentages.map((percentage) => {
      return (totalPageWidth * percentage) / 100;
    });

  doc.autoTable({
    body: tableRows,
    startY: startY,
    margin: margin,
    theme: "grid",
    styles: {
      fontSize: 9,
      cellPadding: 2,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      fontStyle: "normal",
    },
    headStyles: {
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      fontStyle: "bold",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0], fontStyle: "bold" },
      1: { cellWidth: columnWidths[1], fontStyle: "normal" },
      2: { cellWidth: columnWidths[2], fontStyle: "bold" },
      3: { cellWidth: columnWidths[3], fontStyle: "normal" },
    },
  });

  return doc.autoTable.previous.finalY + 10; // Return next available Y position
}

function addDataTable(
  doc,
  combinedData,
  startY,
  margin,
  drawMarginRect,
  letterHead
) {
  const tableRows = combinedData?.map((row, index) => [
    index + 1,
    row?.requestedDucName || row?.instrumentName,
    row?.DUCID && row?.serialNumber
      ? `${row?.DUCID} / ${row?.serialNumber}`
      : `${row?.DUCID} ${row?.serialNumber}`,
    row?.ranges?.replaceAll("#", "").replaceAll("|", ", "),
    row?.quantity,
  ]);

  const totalQuantity = combinedData?.reduce(
    (sum, row) => sum + (row?.quantity || 0),
    0
  );

  tableRows.push([
    {
      content: "Total Quantity",
      colSpan: 3,
      styles: { fontStyle: "bold", halign: "right" },
    },
    { content: totalQuantity, styles: { fontStyle: "bold" } },
  ]);

  doc.autoTable({
    head: [
      ["Sr. No.", "Description", "ID/ Serial No", "Size / Range", "Quantity"],
    ],
    body: tableRows,
    startY,
    margin : {
      top: config.headerHeight + 5,
      right: 5,
      left: 5,
      bottom: config.footerHeight      
    },
    theme: "grid",
    styles: {
      fontSize: 9,
      cellPadding: 2,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      halign: "center",
    },
    headStyles: {
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      fillColor: false,
      lineWidth: 0.2,
    },
    didDrawPage: () => {
      drawMarginRect(doc);
      addHeaderFooter(doc,letterHead);
    },
  });

  return doc.autoTable.previous.finalY + 10;
}

function addHeaderFooter(doc,letterHead) {
  const { width, height } = doc.internal.pageSize,
    margin = 10,
    footerY = height - 35 + 22;

 if (letterHead) {
  doc
    .addImage(UnitechHeader, "PNG", 0, 0, width, config.headerHeight)
    .setFont("helvetica", "bold")
    .setFontSize(10);
  }

  [
    ["Receivers Signature", margin, footerY],
    ["Date", margin, footerY + 10],
    ["For Vaibhav Instrumentation, Pvt.Ltd.", width - margin - doc.getTextWidth("For Vaibhav Instrumentation, Pvt.Ltd."), footerY],
    [
      "Authorised Sign",
      width - margin - doc.getTextWidth("Authorised Sign"),
      footerY + 10,
    ],
  ].forEach(([text, x, y]) => doc.text(text, x, y));

  // if (letterHead) {
  // doc.addImage(UnitechFooter, "PNG", 0, height - 20, width, 20);
  // }
}
